import React, { useState, useEffect } from "react";
import { db } from "../../firebase";
import { CloseOutlined } from "@ant-design/icons";
import { Button, Select, Avatar, Popover, message, Tooltip } from "antd";
import firebase from "../../firebase";
import { useCurrentUserPermissions } from "../../hooks";

const Option = Select.Option;

type Invite = {
  id: string;
  email: string;
  role: string;
};

export default function InviteCard({ project, invite }) {
  const [inviteTemp, setInviteTemp] = useState<Invite>();
  const [copyTipVisible, setCopyTipVisible] = useState(false);
  const [copyTipShown, setCopyTipShown] = useState(false);
  const [popOverVisible, setPopOverVisible] = useState(false);

  const permissions = useCurrentUserPermissions();

  const removeInvite = () => {
    var index = 0;

    var tempInvites = [];

    db.collection("projects")
      .doc(project.id)
      .set(
        {
          invites: firebase.firestore.FieldValue.arrayRemove(inviteTemp?.id),
        },
        { merge: true }
      )
      .then(function () {
        db.collection("invites").doc(inviteTemp?.id).delete();
      })
      .catch(function (error) { });
  };

  const handleChange = (value) => {
    db.collection("invites")
      .doc(inviteTemp?.id)
      .set(
        {
          role: value,
        },
        { merge: true }
      )
      .then(function () { })
      .catch(function (error) { });
  };

  const showCopyTip = () => {
    if (!copyTipShown) {
      setTimeout(() => {
        setCopyTipVisible(true);
        setCopyTipShown(true);
        setTimeout(() => {
          setCopyTipVisible(false);
        }, 2200);
      }, 800);
    }
  };

  const popoverVisibleChange = (visible) => {
    if (!visible) {
      setCopyTipVisible(false);
      setPopOverVisible(visible);
    } else {
      setPopOverVisible(visible);
    }
  };

  useEffect(() => {
    var docRef = db.collection("invites").doc(invite);

    docRef
      .get()
      .then(
        function (doc) {
          if (doc.exists) {
            //
            var inviteData = doc.data();

            inviteData!["id"] = doc.id;

            setInviteTemp(inviteData as Invite);
          } else {
            // doc.data() will be undefined in this case
          }
        }
      )
      .catch(function (error) { });
  }, [invite]);

  if (inviteTemp == null) {
    return null;
  }

  var url = "https://manage.aloa.co/register/" + inviteTemp.id;

  var email = inviteTemp.email;
  var initials = email.charAt(0);

  const content = (
    <div>
      <p>{`Share this link with ${email} so they can join your project`}</p>
      <Tooltip
        visible={copyTipVisible && popOverVisible}
        title="Click on the link to copy"
      >
        <p>
          <a
            onClick={() => {
              // Replaced deprecated execCommand with Clipboard API
              navigator.clipboard.writeText(url)
                .then(() => {
                  message.success("Link copied!");
                });
            }}
          >
            {url}
          </a>
        </p>
      </Tooltip>
    </div>
  );

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        marginBottom: "20px",
      }}
    >
      <div style={{ display: "inline-block" }}>
        <Avatar
          style={{
            color: "#f56a00",
            backgroundColor: "#fde3cf",
            marginRight: "8px",
            verticalAlign: "middle",
          }}
        >
          {initials}
        </Avatar>
      </div>

      <div
        style={{
          display: "inline-block",
          fontFamily: "Avenir Next",
          color: "#000000",
          fontSize: "17px",
          fontWeight: "400",
        }}
      >
        {inviteTemp.email} <span style={{ color: "#b3adad" }}>(invited)</span>
        <Popover
          content={content}
          // @ts-ignore
          onClick={showCopyTip}
          onVisibleChange={popoverVisibleChange}
          title="Invitation Url"
          trigger="click"
        >
          <span style={{ color: "#015bea", cursor: "pointer" }}>
            {" "}
            [invite url]{" "}
          </span>
        </Popover>
      </div>

      <div
        style={{
          display: "inline-block",
          fontFamily: "Avenir Next",
          color: "#000000",
          fontSize: "17px",
          fontWeight: "400",
          marginLeft: "auto",
        }}
      >
        <div style={{ display: "inline-block", marginRight: "5px" }}>
          {permissions?.project?.collaborators?.edit && (
            <Select
              defaultValue={inviteTemp.role}
              style={{ width: 120 }}
              onChange={(e) => handleChange(e)}
            >
              <Option value="viewer">Viewer</Option>
              <Option value="vendor">Vendor</Option>
              <Option value="client">Client</Option>
              <Option value="admin">Admin</Option>
            </Select>
          )}

          {!permissions?.project?.collaborators?.edit && (
            <Select
              defaultValue={inviteTemp.role}
              style={{ width: 120 }}
              onChange={(e) => handleChange(e)}
              disabled
            >
              <Option value="viewer">Viewer</Option>
              <Option value="vendor">Vendor</Option>
              <Option value="client">Client</Option>
              <Option value="admin">Admin</Option>
            </Select>
          )}
        </div>
        {permissions?.project?.collaborators?.delete && (
          <div style={{ display: "inline-block" }}>
            <Button
              shape="circle"
              icon={<CloseOutlined />}
              style={{ border: "none" }}
              onClick={() => removeInvite()}
            />
          </div>
        )}
      </div>
    </div>
  );
}
